import { styled } from '@mui/styles'
import { Box } from '@mui/system'
import { Typography, Table } from 'antd'
import moment, { Moment } from 'moment'
import { ReactNode, useEffect, useState } from 'react'
import { Assembly, AssemblyLine, CallOff } from '../../models/models'
import {
    AssemblyRelation,
    getAssemblyByIdRequest,
    updateAssembly,
    UpdateAssemblyDTO,
} from '../../api/assemblies'
import { toastFailure, toastSuccess, toastWarn } from '../../util/toast'
import { Button, Dialog, Divider, IconButton, Paper } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import CTRLoader from '../ui/loader/CTRloader'
import DeleteIcon from '@mui/icons-material/Delete'
import RefreshIcon from '@mui/icons-material/Refresh'
import TextArea from 'antd/lib/input/TextArea'
import DatePicker from '../ui/calendar/DatePicker'
import { getCallOffById, SingleCallOffRelations } from '../../api/call-off'

const Label = (props: { text: string; isInvalid?: boolean }) => {
    const { text } = props
    return (
        <Box style={{ width: '270px' }}>
            <Typography style={{ color: '#262626', opacity: '45%' }}>{text}</Typography>
        </Box>
    )
}

const InputContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '15px',
})

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
})

const Content = styled(Box)({
    width: '100%',
})

const Section = ({ title, children }: { title: string; children: ReactNode }) => {
    const Container = styled(Box)({
        padding: '15px',
        border: '1px solid #ededed',
        borderRadius: '12px',
    })

    const Title = styled(Typography)({
        fontSize: '16px',
        marginBottom: '15px',
    })

    return (
        <Container>
            <Title>{title}</Title>
            {children}
        </Container>
    )
}

const ContentContainer = styled(Box)({
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '100%',
})

const AddButtonContainer = styled(Box)({
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    width: '100%',
})

const FooterContainer = styled(Box)(() => ({
    display: 'flex',
    width: '95%',
    justifyContent: 'space-between',
    margin: '10px 20px 10px 20px',
}))

const FooterButton = styled(Button)(() => ({
    margin: '10px',
}))

interface UpdateAssemblyModalProps {
    assemblyId: number
    visible: boolean
    onClose(): void
    onOk(): void
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AddedAssemblyLine {}

const isAssemblyLine = (item: AddedAssemblyLine | AssemblyLine): item is AssemblyLine => {
    return !!(item as AssemblyLine).id
}

enum State {
    Loading = 'Loading',
    Found = 'Found',
    NotFound = 'NotFound',
    Error = 'Error',
}

export default function UpdateAssemblyModal({
    assemblyId,
    visible,
    onClose,
    onOk,
}: UpdateAssemblyModalProps) {
    const [assembly, setAssembly] = useState<Assembly>()
    const [dateToComplete, setDateToComplete] = useState<Moment | null>(null)
    const [assemblyComment, setAssemblyComment] = useState<string>('')
    const [amount, setAmount] = useState<string>('')
    const [state, setState] = useState<State>(State.Loading)
    const [addButtonDisabled, setAddButtonDisabled] = useState<boolean>(false)
    const [deletedAssemblyLines, setDeletedAssemblyLines] = useState<number[]>([])
    const [totalCurrentLines, setTotalCurrentLines] = useState<number>(-1)
    const [callOff, setCallOff] = useState<CallOff>()
    //this will have properties when/if we migrate to having multiple built items per assembly
    const [addedAssemblyLines, setAddedAssemblyLines] = useState<AddedAssemblyLine[]>([])

    const isAssemblyLineDeleted = (AssemblyLineId: number) => {
        return deletedAssemblyLines.includes(AssemblyLineId)
    }

    const isAssemblyTransfered = () => {
        return assembly?.assemblyTransferDate !== null
    }

    const isAssemblyLineCompleted = (AssemblyLineId: number) => {
        return !!assembly?.lines.find((item) => item.id === AssemblyLineId && item.completedAt)
    }

    const isDateDisabled = (date: Moment) => {
        return date.isoWeekday() === 6 || date.isoWeekday() === 7 || date.isBefore(moment(), 'day')
    }

    const validateAssembly = () => {
        if (!assembly || !assembly?.lines || assembly.lines.length !== assembly.amount) {
            toastWarn('Assembly has more worksheet items than allocated, contact support')
            onClose()
        }
    }

    const getCallOff = async (callOffId: number) => {
        const response = await getCallOffById(callOffId, {
            relations: [
                SingleCallOffRelations.Assemblies,
                SingleCallOffRelations.AssembliesPallets,
                SingleCallOffRelations.CallOffProducts,
                SingleCallOffRelations.SalesOrder,
                SingleCallOffRelations.SalesOrderCustomer,
                SingleCallOffRelations.SalesOrderProducts,
                SingleCallOffRelations.OrderConfirmations,
            ],
        })

        if (response.successful) {
            setCallOff(response.data)
            let totalCurrentLines = 0
            response.data.assemblies.forEach((asm) => {
                totalCurrentLines += asm.amount
            })
            setTotalCurrentLines(totalCurrentLines)
        } else if (response.status === 404) {
            toastFailure('Could not find call off')
        } else {
            toastFailure(response.message)
        }
    }
    const getAssembly = async () => {
        const response = await getAssemblyByIdRequest(assemblyId, {
            relations: [
                AssemblyRelation.Bom,
                AssemblyRelation.CallOff,
                AssemblyRelation.Assemblers,
                AssemblyRelation.CallOffOrders,
                AssemblyRelation.CallOffProject,
            ],
        })

        if (response.successful) {
            setAssembly(response.data)
            setAmount(response.data.amount.toString())
            setAssemblyComment(response.data.assemblyComment)
            setDateToComplete(moment(response.data.dateToBeCompletedBy))
            getCallOff(response.data.callOffId)
            setState(State.Found)
        } else if (response.status === 404) {
            setState(State.NotFound)
            toastFailure('Could not find the assembly worksheet')
        } else {
            setState(State.Error)
            toastFailure('Something went wrong retrieving the call off')
        }
    }

    useEffect(() => {
        if (assembly) {
            validateAssembly()
        }
    }, [assembly])

    useEffect(() => {
        getAssembly()
    }, [])

    useEffect(() => {
        if (assembly?.assemblyTransferDate !== null) {
            setAddButtonDisabled(true)
        } else if (callOff && totalCurrentLines >= callOff.builtItemCallOffs[0].amount) {
            setAddButtonDisabled(true)
        } else {
            setAddButtonDisabled(false)
        }
    }, [totalCurrentLines])

    const isSubmitDisabled = () => {
        return (
            !assembly ||
            (deletedAssemblyLines.length === 0 &&
                addedAssemblyLines.length === 0 &&
                assemblyComment === assembly.assemblyComment &&
                (dateToComplete === null ||
                    moment(assembly.dateToBeCompletedBy).isSame(dateToComplete, 'day')))
        )
    }

    const addAssemblyLine = () => {
        setAddedAssemblyLines([{}, ...addedAssemblyLines])
        setTotalCurrentLines((prev) => prev + 1)
    }

    const onDeleteAssemblyLine = (AssemblyLineId: number) => {
        setDeletedAssemblyLines([AssemblyLineId, ...deletedAssemblyLines])
        setTotalCurrentLines((prev) => prev - 1)
    }

    const onDeleteAddedAssemblyLine = () => {
        setAddedAssemblyLines(addedAssemblyLines.slice(0, -1))
        setTotalCurrentLines((prev) => prev - 1)
    }

    const restoreDeleteAssemblyLine = (AssemblyLineId: number) => {
        setTotalCurrentLines((prev) => prev + 1)
        setDeletedAssemblyLines(deletedAssemblyLines.filter((v) => v !== AssemblyLineId))
    }

    const columns = [
        {
            title: 'Code',
            width: 300,
            render: () => {
                return assembly?.builtItemCallOff.sopBuiltItemOrderline.builtItem.bomCode
            },
        },
        {
            title: 'Assembler',
            width: 200,
            render: (item: AssemblyLine | AddedAssemblyLine) => {
                if (isAssemblyLine(item)) {
                    return item?.assembler
                        ? `${item.assembler.firstName} ${item.assembler.lastName}`
                        : 'None'
                }
                return 'None'
            },
        },
        {
            title: 'Completed at',
            render: (item: AssemblyLine | AddedAssemblyLine) => {
                if (isAssemblyLine(item)) {
                    return item.completedAt
                        ? moment(item.completedAt).format('DD/MM/YYYY')
                        : 'Not completed'
                }
                return 'Not completed'
            },
        },
        {
            title: '',
            width: 80,
            render: (item: AssemblyLine | AddedAssemblyLine) => {
                if (isAssemblyLine(item)) {
                    return (
                        <>
                            {!isAssemblyLineDeleted(item.id) &&
                                !item.completedAt &&
                                !isAssemblyTransfered() && (
                                    <IconButton onClick={() => onDeleteAssemblyLine(item.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                )}
                            {isAssemblyLineDeleted(item.id) && !isAssemblyTransfered() && (
                                <IconButton onClick={() => restoreDeleteAssemblyLine(item.id)}>
                                    <RefreshIcon />
                                </IconButton>
                            )}
                        </>
                    )
                }
                return (
                    <IconButton onClick={() => onDeleteAddedAssemblyLine()}>
                        <DeleteIcon />
                    </IconButton>
                )
            },
        },
    ]

    const update = async () => {
        if (
            assembly &&
            (dateToComplete !== null ||
                assembly.assemblyComment !== assemblyComment ||
                deletedAssemblyLines.length !== 0 ||
                addedAssemblyLines.length !== 0)
        ) {
            if (totalCurrentLines === 0) {
                toastFailure('Cannot have zero assembly lines, minimum one line is required')
                return
            }
            if (callOff && callOff?.builtItemCallOffs[0].amount < totalCurrentLines) {
                toastFailure(
                    'Cannot have more assembly lines than total amount allowed on call off.'
                )
                return
            }
            const body: UpdateAssemblyDTO = {
                assemblyComment,
            }

            if (addedAssemblyLines.length !== 0) {
                body.addAssemblyLinesAmount = addedAssemblyLines.length
            }
            if (deletedAssemblyLines.length !== 0) {
                body.removeAssemblyLineIds = deletedAssemblyLines
            }

            if (dateToComplete) {
                body.dateToBeCompletedBy = dateToComplete.utcOffset(0, true).toDate()
            }

            const response = await updateAssembly(assembly.id, body)

            if (response.successful) {
                toastSuccess(`Updated assembly successfully`)
                onOk()
                onClose()
            } else {
                if (response.status === 500) {
                    toastFailure('something went wrong updating the production split')
                }
                if (response.status === 409) {
                    toastFailure('Could not make the changes due to conflicts')
                }
            }
        }
    }

    return (
        <Dialog open={visible} onClose={onClose} fullWidth maxWidth="lg" sx={{ zIndex: '100' }}>
            <Paper style={{ height: '100%', maxHeight: '950px' }}>
                <Box
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        padding: '15px 15px 10px 23px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div> Assembly </div>
                    <IconButton aria-label="close" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Divider />
                <ContentContainer>
                    {state === State.Loading && (
                        <Content
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '20px',
                                margin: '20px',
                                width: '100%',
                                height: '400px',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <CTRLoader size="xl" />
                        </Content>
                    )}
                    {assembly && state === State.Found && (
                        <>
                            <InputContainer>
                                <Label text="Assembly comment" />
                                <TextArea
                                    style={{ marginLeft: '0px', width: '500px' }}
                                    rows={2}
                                    name="description"
                                    placeholder="Consider assembling it this way .."
                                    onChange={(event: any) => {
                                        setAssemblyComment(event.target.value)
                                    }}
                                    value={assemblyComment}
                                />
                            </InputContainer>
                            <InputContainer>
                                <Label text="Date to be completed by" />
                                <DatePicker
                                    style={{ width: '200px' }}
                                    format="DD-MM-YYYY"
                                    value={dateToComplete}
                                    disabledDate={isDateDisabled}
                                    onChange={setDateToComplete}
                                />
                            </InputContainer>
                            <AddButtonContainer>
                                <Button
                                    disabled={addButtonDisabled}
                                    sx={{ width: '150px' }}
                                    variant="outlined"
                                    onClick={() => {
                                        addAssemblyLine()
                                    }}
                                >
                                    Add Item
                                </Button>
                            </AddButtonContainer>
                            <Table
                                rowClassName={(AssemblyLine, index) => {
                                    if (!isAssemblyLine(AssemblyLine)) {
                                        return 'added-row'
                                    } else if (isAssemblyLineDeleted(AssemblyLine.id)) {
                                        return 'deleted-row'
                                    } else if (isAssemblyLineCompleted(AssemblyLine.id)) {
                                        return 'disabled-row'
                                    } else {
                                        return ''
                                    }
                                }}
                                size="small"
                                className="no-hover"
                                columns={columns}
                                dataSource={
                                    assembly?.lines
                                        ? [...addedAssemblyLines, ...assembly.lines]
                                        : []
                                }
                                pagination={false}
                            />
                        </>
                    )}
                    <FooterContainer>
                        <Box>
                            <FooterButton variant="outlined" onClick={onClose}>
                                Back
                            </FooterButton>

                            <FooterButton
                                disabled={isSubmitDisabled()}
                                variant="outlined"
                                onClick={update}
                            >
                                Done
                            </FooterButton>
                        </Box>
                    </FooterContainer>
                </ContentContainer>
            </Paper>
        </Dialog>
    )
}

import {
    completeLine,
    fetchAssembly,
    saveFieldValue,
    showCompleteAssemblyLineModal,
} from '../../../../store/assembly-worksheets-slice'
import { styled } from '@mui/material/styles'
import { useAppDispatch } from '../../../../store/hooks'
import _ from 'lodash'
import {
    TableBooleanInput,
    TableDropdownInput,
    TableNumericInput,
    TableTextInput,
} from './TableInputs'
import {
    Assembly,
    AssemblyLine,
    AssemblyLineField,
    BomGroupAssemblyField,
    FieldType,
    User,
    UserRole,
} from '../../../../models/models'
import { Button, Table, TableColumnType } from 'antd'
import moment from 'moment'
import { useMemo } from 'react'
import { CameraOutlined } from '@ant-design/icons'
import { ScannerModalState } from './ActiveAssembly'

const StatusButton = styled('button')`
    padding: 10px 10px;
    background: #fff;
    border-radius: 10px;
    outline: none;
    border-width: 1px;
`

const CompletedButton = styled(StatusButton)`
    color: #146a5e;
    border-color: #146a5e;
    background: rgba(20, 106, 94, 0.02);
`

const PendingButton = styled(StatusButton)`
    color: #3500a0;
    border-color: #3500a0;
    background: rgba(53, 0, 160, 0.02);
    opacity: ${(props) => (props.disabled ? 0.4 : 1)};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`

const Link = styled('a')`
    color: #3500a0;
    text-decoration: underline;
    font-weight: 500;
`

const ColumnWidth: { [key in FieldType]: number } = {
    [FieldType.Text]: 200,
    [FieldType.Boolean]: 125,
    [FieldType.Numeric]: 200,
    [FieldType.Dropdown]: 200,
}

const totalWidth = (fields: Array<BomGroupAssemblyField>) => {
    return fields.reduce((acc: number, field: BomGroupAssemblyField) => {
        return acc + (ColumnWidth[field.assemblyField.type] ?? 100)
    }, 0)
}

interface Props {
    assembly: Assembly
    user: User
    setScannerModalState: React.Dispatch<React.SetStateAction<ScannerModalState>>
    scannerModalState: ScannerModalState
}

export function ActiveAssemblyIndividualComplete(props: Props) {
    const dispatch = useAppDispatch()

    // should disable a field if the assembly is not started, or if the assembly line is completed and the user is not a system admin
    const isFieldDisabled = (assembly: Assembly, assemblyLine: AssemblyLine) => {
        const isAssemblyStarted = !!assembly.startedAt
        const isAssemblyLineCompleted = assemblyLine.completedAt
        const rolesThatCanEditPostCompletion = [UserRole.SystemAdmin, UserRole.Assembler]
        return (
            !isAssemblyStarted ||
            (isAssemblyLineCompleted && !rolesThatCanEditPostCompletion.includes(props.user.role))
        )
    }

    const { assembly, user } = props

    const bom = assembly.builtItemCallOff.sopBuiltItemOrderline.builtItem

    const isCompletable = (assemblyLine: AssemblyLine) => {
        const hasUndefinedRequiredFields = assemblyLine.assemblyLineFields.some(
            (field: AssemblyLineField) => {
                const assemblyField = bom.group?.bomGroupAssemblyFields
                    ?.slice()
                    ?.find(
                        (bomGroupAssemblyField: BomGroupAssemblyField) =>
                            bomGroupAssemblyField.assemblyFieldId === field.assemblyFieldId
                    )?.assemblyField

                return assemblyField && assemblyField.isRequired && !field.value
            }
        )
        return !hasUndefinedRequiredFields
    }

    const fields = useMemo(() => {
        const fields: Array<BomGroupAssemblyField> =
            bom.group?.bomGroupAssemblyFields
                ?.slice()
                .sort(
                    (a: BomGroupAssemblyField, b: BomGroupAssemblyField) =>
                        Number(a.columnOrder) - Number(b.columnOrder)
                ) ?? []

        return fields
    }, [bom])

    const columns = useMemo(() => {
        const columns: Array<TableColumnType<AssemblyLine>> = [
            {
                title: 'No.',
                dataIndex: 'id',
                key: 'id',
                width: 50,
                render: (assemblyLine: AssemblyLine, record, index) => <span>{assemblyLine}</span>,
            },
        ]

        if (fields) {
            fields.forEach((bomGroupAssemblyField: BomGroupAssemblyField) => {
                columns.push({
                    title: bomGroupAssemblyField.assemblyField.name,
                    key: bomGroupAssemblyField.id.toString(),
                    width: ColumnWidth[bomGroupAssemblyField.assemblyField.type] ?? 100,
                    render: (assemblyLine: AssemblyLine) => {
                        const assemblyLineField = assemblyLine?.assemblyLineFields?.find(
                            (field) =>
                                bomGroupAssemblyField.assemblyFieldId === field.assemblyFieldId
                        )

                        if (!assemblyLineField) {
                            return
                        }

                        if (bomGroupAssemblyField.assemblyField.type === FieldType.Boolean) {
                            return (
                                <TableBooleanInput
                                    value={Boolean(assemblyLineField.value)}
                                    onSave={(value, afterSave) => {
                                        saveFieldValue(
                                            assemblyLineField.id,
                                            value ? 'true' : '',
                                            () => {
                                                dispatch(fetchAssembly(assembly.id))
                                                afterSave()
                                            }
                                        )
                                    }}
                                    active={!isFieldDisabled(assembly, assemblyLine)}
                                />
                            )
                        }

                        if (bomGroupAssemblyField.assemblyField.type === FieldType.Text) {
                            return (
                                <div
                                    key={assemblyLineField.id}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <TableTextInput
                                        value={assemblyLineField.value}
                                        onSave={(value, afterSave) => {
                                            saveFieldValue(assemblyLineField.id, value, () => {
                                                dispatch(fetchAssembly(assembly.id))
                                                afterSave()
                                            })
                                        }}
                                        active={!isFieldDisabled(assembly, assemblyLine)}
                                    />
                                    {bomGroupAssemblyField.assemblyField.isScannable && (
                                        <Button
                                            style={{ marginLeft: 'auto' }}
                                            shape="circle"
                                            onClick={() => {
                                                props.setScannerModalState({
                                                    isVisible: true,
                                                    hasManualMode: true,
                                                    assemblyLineFieldId: assemblyLineField.id,
                                                })
                                            }}
                                            disabled={isFieldDisabled(assembly, assemblyLine)}
                                            icon={<CameraOutlined />}
                                        />
                                    )}
                                </div>
                            )
                        }

                        if (bomGroupAssemblyField.assemblyField.type === FieldType.Dropdown) {
                            return (
                                <TableDropdownInput
                                    options={
                                        bomGroupAssemblyField.assemblyField.dropdownOptions ?? []
                                    }
                                    value={assemblyLineField.value}
                                    onSave={(value, afterSave) => {
                                        saveFieldValue(assemblyLineField.id, value, () => {
                                            dispatch(fetchAssembly(assembly.id))
                                            afterSave()
                                        })
                                    }}
                                    active={!isFieldDisabled(assembly, assemblyLine)}
                                />
                            )
                        }

                        if (bomGroupAssemblyField.assemblyField.type === FieldType.Numeric) {
                            return (
                                <TableNumericInput
                                    step={1}
                                    value={
                                        assemblyLineField.value
                                            ? Number(assemblyLineField.value)
                                            : null
                                    }
                                    onSave={(value, afterSave) => {
                                        saveFieldValue(
                                            assemblyLineField.id,
                                            value?.toString() ?? null,
                                            () => {
                                                dispatch(fetchAssembly(assembly.id))
                                                afterSave()
                                            }
                                        )
                                    }}
                                    active={!isFieldDisabled(assembly, assemblyLine)}
                                />
                            )
                        }

                        return <span>{bomGroupAssemblyField.id}</span>
                    },
                })
            })
        }

        if (assembly.startedAt) {
            columns.push({
                title: 'Complete',
                key: 'complete',
                width: 100,
                render: (assemblyLine: AssemblyLine) => {
                    if (assemblyLine.completedAt) {
                        return (
                            <CompletedButton>
                                {`${moment(assemblyLine.completedAt).format(
                                    'DD/MM/YYYY HH:mm'
                                )} by ${
                                    assemblyLine?.assembler
                                        ? `${assemblyLine.assembler.firstName} ${assemblyLine.assembler.lastName}`
                                        : 'Unknown'
                                }`}
                            </CompletedButton>
                        )
                    }

                    return (
                        <PendingButton
                            disabled={!isCompletable(assemblyLine)}
                            onClick={() => {
                                dispatch(showCompleteAssemblyLineModal(assemblyLine.id))
                            }}
                        >
                            Complete
                        </PendingButton>
                    )
                },
            })
        }
        return columns
    }, [fields])

    const assemblyLines = [...assembly.lines].filter((line) => line.timeDeletedAt === null)
    assemblyLines.sort((a, b) => a.id - b.id)

    return (
        <>
            <Table
                rowClassName={(assemblyLine: AssemblyLine, index) => {
                    if (assemblyLine.assemblerId === user.id) {
                        return 'green-row'
                    }

                    return ''
                }}
                pagination={false}
                size="small"
                dataSource={assemblyLines}
                columns={columns}
                style={{ minWidth: totalWidth(fields) }}
            />
        </>
    )
}

import { styled } from '@mui/styles'
import { Box } from '@mui/system'
import PageHeader from '../ui/PageHeader'
import Booking from './logistics/booking/Booking'
import { useEffect, useMemo, useState } from 'react'
import Dispatch from './logistics/dispatch/Dispatch'
import Transfer from './logistics/transfer/Transfer'
import { CallOff, CallOffStatus, SalesOrder, TaskDepartment } from '../../models/models'
import { Urls } from '../../util/urls'
import TaskBar from './tasks/TaskBar'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import {
    GetDispatchWorksheetsDTO,
    getDispatchWorksheetsRequest,
    getTransferWorksheetsRequest,
    TransferType,
    TransferWorksheet,
} from '../../api/logistics-worksheet'
import moment from 'moment'
import { toastFailure, toastSuccess } from '../../util/toast'
import {
    TransferFilter,
    setActiveView,
    LogisticsWorksheetView,
    FilterDispatchDate,
    setCallOffProjectIds,
} from '../../store/logistics-worksheet-slice'
import { useAppDispatch } from '../../store/hooks'
import { Tabs, TabsProps } from 'antd'
import { syncSalesOrderWithSage } from '../../api/sage'

const Container = styled(Box)({
    margin: '15px',
})

export enum TransferSortBy {
    AssemblyDateToBeCompletedBy = 'AssemblyDateToBeCompletedBy',
    CallOffDateToDispatch = 'CallOffDateToDispatch',
}

export default function WorksheetsLogistics() {
    const { user } = useSelector((state: RootState) => state.authentication)
    const [syncRunning, setSyncRunning] = useState<boolean>(false)
    const [callOffs, setCallOffs] = useState<CallOff[]>([])
    const [transfers, setTransfers] = useState<TransferWorksheet[]>([])
    const dispatch = useAppDispatch()
    const {
        activeView,
        filterCallOffId,
        filterProjectName,
        filterSavNumber,
        filterDispatchDate,
        filterNoAssemblies,
        filterAssemblyId,
        filterWarehouseId,
        filterProjectManager,
    } = useSelector((state: RootState) => state.logisticsWorksheets)

    const syncSalesOrder = async (salesOrder: SalesOrder) => {
        if (!syncRunning) {
            setSyncRunning(true)
            const response = await syncSalesOrderWithSage(salesOrder.id)
            if (response.successful) {
                if (
                    response?.data.length > 0 &&
                    response.data.find((salesOrder) => salesOrder?.id !== null)
                ) {
                    // Show success and fetch call off data to update cards
                    toastSuccess('Sales Order updated successfully!')
                    fetchCallOffs()
                    setSyncRunning(false)
                } else {
                    toastFailure('Sales Order could not be updated or found in Sage.')
                    setSyncRunning(false)
                }
            } else {
                toastFailure('Sales Order could not be updated or found in Sage.')
                setSyncRunning(false)
            }
        }
    }

    const fetchCallOffs = async () => {
        const requestParams: GetDispatchWorksheetsDTO = {
            warehouseId: filterWarehouseId,
        }

        const response = await getDispatchWorksheetsRequest(requestParams)
        if (response.successful) {
            setCallOffs(response.data)
        }
    }

    const dispatchCallOffs = useMemo(() => {
        return callOffs
            .filter((c) => {
                const projectManager =
                    c.project?.projectManagers.find((manager) => {
                        if (manager.id === filterProjectManager) {
                            return manager.id
                        }
                    }) ?? null

                const statusCheck =
                    c.status === CallOffStatus.InProduction ||
                    (c.status === CallOffStatus.Approved && c.assemblies.length < 1)

                return (
                    c.warehouseId === filterWarehouseId &&
                    (filterNoAssemblies
                        ? c.assemblies.length < 1
                        : c.assemblies.length > 1 || statusCheck) &&
                    !c.isOnHold &&
                    (filterProjectName.length < 1 ||
                        c.project.name.toLowerCase().includes(filterProjectName.toLowerCase())) &&
                    (!filterSavNumber ||
                        c.project.opportunityReference
                            .toLowerCase()
                            .includes(filterSavNumber.toLowerCase())) &&
                    (filterCallOffId.length < 1 || c.id.toString().includes(filterCallOffId)) &&
                    (filterDispatchDate === FilterDispatchDate.Upcoming ||
                        (filterDispatchDate === FilterDispatchDate.Today &&
                            moment(c.customerDispatchDate).isSame(moment(), 'day'))) &&
                    (!filterProjectManager || projectManager)
                )
            })
            .sort((callOff1: CallOff, callOff2: CallOff) => {
                return (
                    moment(callOff1.customerDispatchDate).unix() -
                    moment(callOff2.customerDispatchDate).unix()
                )
            })
    }, [
        callOffs,
        filterProjectName,
        filterSavNumber,
        filterCallOffId,
        filterDispatchDate,
        filterNoAssemblies,
        filterProjectManager,
    ])

    useEffect(() => {
        const projectIds = dispatchCallOffs.map((callOff) => {
            return callOff.projectId
        })
        dispatch(setCallOffProjectIds(projectIds))
    }, [dispatchCallOffs])

    useEffect(() => {
        fetchTransfers()
    }, [filterWarehouseId])

    useEffect(() => {
        fetchCallOffs()
    }, [filterWarehouseId])

    const fetchTransfers = async () => {
        const response = await getTransferWorksheetsRequest(filterWarehouseId)
        if (response.successful) {
            const { transfers } = response.data
            setTransfers(transfers)
        } else {
            toastFailure('Could not fetch worksheets')
        }
    }

    useEffect(() => {
        fetchTransfers()
    }, [filterWarehouseId])

    const filteredTransfers = useMemo(() => {
        return transfers
            .filter((transfer) => {
                const c = transfer.item.callOff
                return (
                    (!filterProjectName ||
                        c.project.name.toLowerCase().includes(filterProjectName.toLowerCase())) &&
                    (!filterSavNumber ||
                        c.project.opportunityReference
                            .toLowerCase()
                            .includes(filterSavNumber.toLowerCase())) &&
                    (!filterCallOffId || c.id.toString().includes(filterCallOffId))
                )
            })
            .filter((transfer) => {
                const orderConfirmations = transfer.item.callOff?.orderConfirmations
                if (orderConfirmations) {
                    for (const orderConfirmation of orderConfirmations) {
                        if (!orderConfirmation.deliveredDate) {
                            return false
                        }
                    }
                }
                return true
            })
            .filter((transfer) => {
                return transfer.item.id.toString().includes(filterAssemblyId)
            })
            .filter((transfer) => {
                if (transfer.item.callOff?.goodsInDate) {
                    return moment(transfer.item.callOff?.goodsInDate).isSameOrBefore(moment())
                } else if (!transfer.item.callOff?.goodsInDate) {
                    return true
                }
                return false
            })
            .sort((transfer1: TransferWorksheet, transfer2: TransferWorksheet) => {
                return transfer1.item.id - transfer2.item.id
            })
            .sort((transfer1: TransferWorksheet, transfer2: TransferWorksheet) => {
                return (
                    moment(transfer1.item.callOff?.goodsInDate).unix() -
                    moment(transfer2.item.callOff?.goodsInDate).unix()
                )
            })
    }, [
        transfers,
        filterAssemblyId,
        filterCallOffId,
        filterCallOffId,
        filterSavNumber,
        filterProjectName,
    ])

    const tabsOptions: TabsProps['items'] = [
        {
            key: LogisticsWorksheetView.Booking,
            label: `Booking`,
            children: <Booking warehouseId={filterWarehouseId} />,
        },
        {
            key: LogisticsWorksheetView.Transfer,
            label: `Transfer`,
            children: (
                <Transfer
                    warehouseId={filterWarehouseId}
                    fetchTransfers={fetchTransfers}
                    transferWorksheets={filteredTransfers}
                />
            ),
        },
        {
            key: LogisticsWorksheetView.Dispatch,
            label: `Dispatch`,
            children: (
                <Dispatch
                    callOffs={dispatchCallOffs}
                    fetchCallOffs={fetchCallOffs}
                    syncSalesOrder={syncSalesOrder}
                />
            ),
        },
    ]

    return (
        <Container>
            <PageHeader
                title="Logistics Worksheets"
                breadcrumbs={[
                    { link: Urls.Landing, name: 'Main Page' },
                    {
                        link: Urls.WorksheetsLogistics,
                        name: 'Logistics Worksheets',
                    },
                ]}
            />

            <TaskBar department={TaskDepartment.LOGISTICS} warehouseId={filterWarehouseId} />

            <Tabs
                type="card"
                activeKey={activeView}
                onChange={(view) => dispatch(setActiveView(view as LogisticsWorksheetView))}
                defaultActiveKey={LogisticsWorksheetView.Booking}
                items={tabsOptions}
            />
        </Container>
    )
}

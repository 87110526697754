import { Button, Chip, Divider, styled, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { CallOff, SalesOrder, WarehouseId } from '../../../../models/models'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setCallOffDispatchView } from '../../../../store/warehouse-map-slice'
import { Urls } from '../../../../util/urls'
import { getCallOffById, SingleCallOffRelations } from '../../../../api/call-off'
import { toastFailure, toastWarn } from '../../../../util/toast'
import { isFlatstation } from '../../../../util/util'
import SyncIcon from '@mui/icons-material/Sync'
import { useMemo } from 'react'
import { throttle } from 'lodash'

interface TransferCardProps {
    callOff: CallOff
    onClick: () => void
    syncSalesOrder: (salesOrder: SalesOrder) => void
    openDetailsModal: (callOff: CallOff) => void
    openDispatchModal: (callOff: CallOff) => void
}
const CardRow = (props: { description: string; content: string; disableDivider?: boolean }) => {
    const Row = styled(Box)(() => ({
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: '10px',
    }))

    const RowContent = styled(Box)(() => ({
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginBottom: '6px',
    }))

    const RowDescriptionContainer = styled(Box)(() => ({
        paddingLeft: '10px',
        width: '40%',
    }))

    const RowContentContainer = styled(Box)(() => ({
        width: '60%',
        paddingRight: '10px',
    }))

    const { description, content, disableDivider } = props
    return (
        <Row>
            <RowContent>
                <RowDescriptionContainer>
                    <Typography sx={{ color: '#26262690' }}>{description}</Typography>
                </RowDescriptionContainer>
                <RowContentContainer>
                    <Typography>{content}</Typography>
                </RowContentContainer>
            </RowContent>
            {!disableDivider && <Divider />}
        </Row>
    )
}

const CardHeader = (props: {
    callOff: CallOff
    openDetailsModal: (callOff: CallOff) => void
    allAssembliesCompleted: boolean
    partialAssembliesCompleted: boolean | undefined
    noAssemblies: boolean | undefined
    totalInactiveAssemblyBuiltItems: number
}) => {
    const {
        callOff,
        openDetailsModal,
        allAssembliesCompleted,
        partialAssembliesCompleted,
        noAssemblies,
        totalInactiveAssemblyBuiltItems,
    } = props
    const Row = styled(Box)(() => ({
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        padding: '5px',
    }))

    const ProjectName = styled(Box)(() => ({
        justifyContent: 'center',
        alignSelf: 'center',
        paddingLeft: '10px',
    }))

    const DetailsButton = styled(Button)(() => ({
        padding: '0px',
        minWidth: '0px',
    }))

    const PlaceholderChip = styled(Chip)(() => ({
        marginTop: '10px',
        whiteSpace: 'normal',
        flexWrap: 'wrap',
        backgroundColor: 'white',
        color: 'white',
        borderColor: 'white',
        width: '100%',
    }))

    const CompletedChip = styled(Chip)(() => ({
        marginTop: '10px',
        whiteSpace: 'normal',
        flexWrap: 'wrap',
        backgroundColor: '#DCF7E9',
        color: '#00898A',
        borderColor: '#00898A',
        width: '100%',
    }))

    const PartialChip = styled(Chip)(() => ({
        marginTop: '10px',
        whiteSpace: 'normal',
        flexWrap: 'wrap',
        backgroundColor: '#FFD591',
        color: '#FA8C16',
        borderColor: '#FA8C16',
        width: '100%',
    }))

    return (
        <div>
            <Row>
                <ProjectName>{callOff.project.name}</ProjectName>
                <DetailsButton
                    variant="text"
                    size="small"
                    onClick={() => openDetailsModal(callOff)}
                >
                    <Typography>Call-off Details</Typography>
                </DetailsButton>
            </Row>
            <Row>
                <PlaceholderChip
                    hidden={
                        allAssembliesCompleted ||
                        partialAssembliesCompleted ||
                        noAssemblies ||
                        totalInactiveAssemblyBuiltItems < 0
                    }
                />

                <CompletedChip variant="outlined" label="Stock Only" hidden={!noAssemblies} />

                {allAssembliesCompleted ? (
                    <CompletedChip
                        variant="outlined"
                        label="Assembly Complete"
                        hidden={!allAssembliesCompleted}
                    />
                ) : (
                    partialAssembliesCompleted && (
                        <PartialChip
                            variant="outlined"
                            label="Partially Complete"
                            hidden={!partialAssembliesCompleted}
                        />
                    )
                )}
            </Row>
        </div>
    )
}

const CardFooter = (props: {
    callOff: CallOff
    onClick: () => void
    syncSalesOrder: (salesOrder: SalesOrder) => void
    allAssembliesCompleted: boolean
    noAssemblies: boolean
    partialAssembliesCompleted: boolean
    assembliesToDispatch: boolean
}) => {
    const {
        callOff,
        noAssemblies,
        assembliesToDispatch,
        allAssembliesCompleted,
        partialAssembliesCompleted,
        syncSalesOrder,
    } = props || {}
    const Row = styled(Box)(() => ({
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'flex-end',
    }))

    const TransferButton = styled(Button)(() => ({
        padding: '0px',
        minWidth: '0px',
        margin: '10px 10px 5px 0px',
    }))

    const SyncButton = styled(Button)(() => ({
        padding: '0px',
        minWidth: '0px',
        margin: '10px 10px 5px 10px',
    }))

    const showDispatch = () => {
        if (noAssemblies) {
            // Only stock items
            return true
        } else if (assembliesToDispatch && partialAssembliesCompleted) {
            // Some assemblies completed and ready for dispatch
            return true
        } else if (assembliesToDispatch && allAssembliesCompleted) {
            // All assemblies complete
            return true
        } else if (assembliesToDispatch) {
            // At least one assembly to dispatch
            return true
        } else {
            return false
        }
    }

    const throttledSync = useMemo(() => throttle(syncSalesOrder, 10000), [])

    return (
        <Row style={{ justifyContent: 'space-between' }}>
            <SyncButton
                variant="outlined"
                size="small"
                onClick={() => throttledSync(callOff.salesOrder)}
            >
                <SyncIcon />
                <Typography>Details</Typography>
            </SyncButton>
            {showDispatch() && (
                <TransferButton variant="outlined" size="small" onClick={props.onClick}>
                    Dispatch call off to customer
                </TransferButton>
            )}
        </Row>
    )
}

const CardContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '400px',
    margin: '20px',
    paddingBottom: '10px',
    backgroundColor: '#FFF',
    boxShadow: `rgba(100, 100, 111, 0.2) 0px 7px 29px 0px`,
}))

export default function DispatchCard(props: TransferCardProps) {
    const { callOff, openDetailsModal, openDispatchModal, syncSalesOrder } = props
    const dispatch = useDispatch()
    const history = useHistory()

    const totalAssemblies = callOff?.assemblies.length
    let completedAssemblies = 0
    let completedAssemblyAmount = 0
    callOff.assemblies.forEach((assembly) => {
        if (assembly.completedAt !== null) {
            completedAssemblies += 1
            completedAssemblyAmount += assembly?.amount
        }
        return
    })

    let dispatchedAssemblies = 0
    let partialAssembliesCompleted = false
    let totalInactiveAssemblyBuiltItems = 0
    let totalBuiltItems = 0

    const doesCallOffHaveHIU = (callOff: CallOff) => {
        if (callOff.warehouseId !== WarehouseId.Monument) {
            return false
        }
        const foundHIU = callOff.builtItemCallOffs.find((builtItemCallOff) => {
            return isFlatstation(builtItemCallOff.sopBuiltItemOrderline.builtItem)
        })
        return foundHIU !== undefined
    }

    const isCreatePalletEnabled = (callOff: CallOff): boolean | undefined => {
        const buildItemCallOffGroup = callOff.builtItemCallOffs.find((builtItemCallOff) => {
            return builtItemCallOff.sopBuiltItemOrderline.builtItem.group?.createPalletEnabled
        })
        return buildItemCallOffGroup?.sopBuiltItemOrderline.builtItem.group?.createPalletEnabled
    }

    const isScandia = (callOff: CallOff): boolean => {
        return callOff.warehouseId === WarehouseId.Scandia
    }

    const addressLineExists = (callOffDeliveryAddress: string, finalAddressLine = false) => {
        if (callOffDeliveryAddress) {
            if (finalAddressLine) {
                return callOffDeliveryAddress.toString()
            }
            return (callOffDeliveryAddress + ', ').toString()
        }

        return ''
    }

    const fullDeliveryAddress = ''.concat(
        addressLineExists(callOff.salesOrder?.deliveryAddress?.address1),
        addressLineExists(callOff.salesOrder?.deliveryAddress?.address2),
        addressLineExists(callOff.salesOrder?.deliveryAddress?.address3),
        addressLineExists(callOff.salesOrder?.deliveryAddress?.address4),
        addressLineExists(callOff.salesOrder?.deliveryAddress?.postcode, true)
    )

    // get total assemblies which have assembly inactive in bomgroup
    callOff.builtItemCallOffs.forEach((builtItemCallOff) => {
        if (
            builtItemCallOff.sopBuiltItemOrderline.builtItem.group &&
            !builtItemCallOff.sopBuiltItemOrderline.builtItem.group.assemblyEnabled
        ) {
            totalInactiveAssemblyBuiltItems += builtItemCallOff.amount

            // Built items with no assembly are treated as complete
            completedAssemblyAmount += 1
        }
        totalBuiltItems = builtItemCallOff.amount
    })

    // Get amount of dispatched assemblies
    callOff.builtItemCallOffs.forEach((builtItemCallOff) => {
        return builtItemCallOff.goodsDispatched.forEach((goods) => {
            if (goods?.amountDispatched) {
                dispatchedAssemblies += goods.amountDispatched
            }
        })
    })

    let assembliesToDispatch =
        completedAssemblies > 0 && completedAssemblyAmount > 0
            ? dispatchedAssemblies !== completedAssemblyAmount &&
              completedAssemblyAmount - dispatchedAssemblies !== 0
            : false

    let allAssembliesCompleted =
        totalAssemblies > 0 ? completedAssemblies === totalAssemblies : false

    if (completedAssemblies > 0) {
        // Partial status should only show if at least one complete and multiple assemblies on call off
        partialAssembliesCompleted =
            totalAssemblies > 1 ? totalAssemblies > completedAssemblies : false
    }

    // Inactive Assembly Bom group logic
    if (
        totalInactiveAssemblyBuiltItems > 0 &&
        dispatchedAssemblies - totalInactiveAssemblyBuiltItems !== totalBuiltItems
    ) {
        partialAssembliesCompleted = true
        // if all inactive assembly not dispatched and all assemblies complete
        if (
            dispatchedAssemblies - totalInactiveAssemblyBuiltItems !== totalBuiltItems &&
            completedAssemblies === totalAssemblies
        ) {
            allAssembliesCompleted = true
        }
    }

    if (
        totalInactiveAssemblyBuiltItems > 0 &&
        dispatchedAssemblies !== totalInactiveAssemblyBuiltItems &&
        dispatchedAssemblies - totalInactiveAssemblyBuiltItems !== totalInactiveAssemblyBuiltItems
    ) {
        assembliesToDispatch = true
    }

    const noAssemblies =
        (totalAssemblies === 0 || totalBuiltItems === totalInactiveAssemblyBuiltItems) &&
        callOff.stockItemCallOffs.length > 0 &&
        !allAssembliesCompleted &&
        !partialAssembliesCompleted

    return (
        <CardContainer>
            <CardHeader
                callOff={callOff}
                openDetailsModal={openDetailsModal}
                allAssembliesCompleted={allAssembliesCompleted}
                partialAssembliesCompleted={partialAssembliesCompleted}
                noAssemblies={noAssemblies}
                totalInactiveAssemblyBuiltItems={totalInactiveAssemblyBuiltItems}
            />
            <CardRow description="Call off id" content={callOff.id.toString()} />
            <CardRow
                description="Call off customer dispatch"
                content={moment(callOff.customerDispatchDate).format('DD/MM')}
            />

            <CardRow
                description="Call off customer delivery"
                content={moment(callOff.deliveryDate).format('DD/MM')}
            />
            <CardRow description="Dispatch comment" content={callOff.dispatchComment} />
            <CardRow description="Address" content={fullDeliveryAddress} />
            <CardRow
                description="Contacts"
                content={callOff.salesOrder?.contacts
                    .map((c) => `${c.name} ${c.phoneNumber}`)
                    .join(', \n \n \n')}
            />
            <CardRow
                description="Customer Document No"
                content={callOff.salesOrder?.customerDocumentNo}
            />
            <CardFooter
                callOff={callOff}
                allAssembliesCompleted={allAssembliesCompleted}
                noAssemblies={noAssemblies}
                partialAssembliesCompleted={partialAssembliesCompleted}
                assembliesToDispatch={assembliesToDispatch}
                syncSalesOrder={syncSalesOrder}
                onClick={async () => {
                    if (callOff.isOnHold) {
                        toastWarn('Cannot dispatch the call off because it is on hold')
                        return
                    }
                    if (isCreatePalletEnabled(callOff) === undefined) {
                        //perform normal flow
                        if (!doesCallOffHaveHIU(callOff)) {
                            openDispatchModal(callOff)
                            return
                        }
                    } else if (isCreatePalletEnabled(callOff) === false) {
                        openDispatchModal(callOff)
                        return
                    } else if (isScandia(callOff)) {
                        openDispatchModal(callOff)
                        return
                    }

                    const response = await getCallOffById(callOff.id, {
                        relations: [
                            SingleCallOffRelations.Assemblies,
                            SingleCallOffRelations.AssembliesPallets,
                        ],
                    })
                    if (response.successful) {
                        dispatch(setCallOffDispatchView(response.data))
                        history.push(Urls.WarehouseMap)
                    } else {
                        toastFailure(response.message)
                        return
                    }
                }}
            ></CardFooter>
        </CardContainer>
    )
}

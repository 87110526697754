import { SalesOrder } from '../models/models'
import backendConnection from './util/backend-connection'
import { Response } from './util/with-response-formatter-interceptor'

export interface SageResponse {
    token: string
    refresh_token: string
    access_token: string
    token_type: string
    id_token: string
    scope: string
    expires_in: number
}

export const getToken = (authorizationCode: string): Promise<Response<SageResponse>> =>
    backendConnection.request({
        url: `sage/token`,
        data: {
            authorizationCode,
        },
        method: 'POST',
    })

export const syncSalesOrderWithSage = (salesOrderId: number): Promise<Response<SalesOrder[]>> =>
    backendConnection.request({
        url: `sage/sync/sales-order/${salesOrderId}`,
        method: 'POST',
    })

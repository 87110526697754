import { styled } from '@mui/material'
import { Label } from '../../../ui/Label'
import { FieldContainer } from '../../../ui/FIeldContainer'
import { Input, Select } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import {
    DispatchedStatus,
    PriceCalculation,
    setFilters,
} from '../../../../store/project-call-off-slice'
import { useMemo } from 'react'
import { CallOffStatus } from '../../../../models/models'

const FilterContainer = styled('div')`
    width: 100%;
    display: flex;
    flex-directi: row;
    flex-wrap: wrap;
    padding: 0px 0px 10px 0px;
    gap: 15px;
`

interface Props {
    listView?: boolean
}

export const CallOffFilterBar = (props: Props) => {
    const { filters, projectBuiltItems, salesOrders } = useAppSelector(
        (state) => state.projectCallOffs
    )
    const { listView } = props

    const dispatch = useAppDispatch()

    const excludeBomCodeOptions = useMemo(
        () =>
            projectBuiltItems.map((item) => ({
                value: item.builtItem.bomCode,
                label: item.builtItem.bomCode,
            })),
        [projectBuiltItems]
    )

    const excludedSalesOrderOptions = useMemo(
        () =>
            salesOrders.map((salesOrder) => ({
                value: salesOrder.documentNo,
                label: salesOrder.documentNo,
            })),
        [salesOrders]
    )

    const dispatchStatusOptions = [
        { value: DispatchedStatus.All, label: 'All' },
        { value: DispatchedStatus.Dispatched, label: 'Dispatched' },
        { value: DispatchedStatus.NotDispatched, label: 'Not Dispatched' },
    ]

    const callOffStatusOptions = [
        { value: null, label: 'All' },
        { value: CallOffStatus.Requested, label: 'Requested' },
        { value: CallOffStatus.Approved, label: 'Approved' },
        { value: CallOffStatus.InProduction, label: 'In Production' },
        { value: CallOffStatus.Completed, label: 'Completed' },
        { value: CallOffStatus.Dispatched, label: 'Dispatched' },
        { value: 'task', label: 'Task' },
    ]

    const priceCalculationOptions = [
        { value: PriceCalculation.Total, label: 'Total' },
        { value: PriceCalculation.Each, label: 'Each' },
    ]

    return (
        <FilterContainer>
            {!listView && (
                <>
                    <FieldContainer>
                        <Label>Status</Label>
                        <Select
                            style={{ width: '180px' }}
                            value={filters.dispatchedStatus}
                            onChange={(value: DispatchedStatus) =>
                                dispatch(setFilters({ ...filters, dispatchedStatus: value }))
                            }
                            options={dispatchStatusOptions}
                        />
                    </FieldContainer>
                    <FieldContainer>
                        <Label>Exclude Bom Components</Label>
                        <Select
                            mode="multiple"
                            style={{ width: '250px' }}
                            placeholder="Select Boms to exclude"
                            defaultValue={filters.excludedBomCodes}
                            options={excludeBomCodeOptions}
                            onChange={(value: string[]) =>
                                dispatch(setFilters({ ...filters, excludedBomCodes: value }))
                            }
                        />
                    </FieldContainer>
                    <FieldContainer>
                        <Label>Exclude Sales Orders</Label>
                        <Select
                            mode="multiple"
                            style={{ width: '250px' }}
                            placeholder="Select Sales Orders to exclude"
                            defaultValue={filters.excludedSalesOrders}
                            options={excludedSalesOrderOptions}
                            onChange={(value: string[]) =>
                                dispatch(setFilters({ ...filters, excludedSalesOrders: value }))
                            }
                        />
                    </FieldContainer>
                    <FieldContainer>
                        <Label>Price</Label>
                        <Select
                            style={{ width: '180px' }}
                            value={filters.priceCalculation}
                            onChange={(value: PriceCalculation) =>
                                dispatch(setFilters({ ...filters, priceCalculation: value }))
                            }
                            options={priceCalculationOptions}
                        />
                    </FieldContainer>
                </>
            )}
            {listView && (
                <>
                    <FieldContainer>
                        <Label>Status</Label>
                        <Select
                            style={{ width: '180px' }}
                            value={filters.callOffStatus}
                            onChange={(value: CallOffStatus | string | null) =>
                                dispatch(setFilters({ ...filters, callOffStatus: value }))
                            }
                            options={callOffStatusOptions}
                        />
                    </FieldContainer>
                    <FieldContainer>
                        <Label>Call Off Id</Label>
                        <Input
                            placeholder="Call Off Id"
                            autoComplete="off"
                            type="number"
                            value={filters.filterCallOffId}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                dispatch(
                                    setFilters({ ...filters, filterCallOffId: e.target.value })
                                )
                            }}
                        />
                    </FieldContainer>
                </>
            )}
        </FilterContainer>
    )
}

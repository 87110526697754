import { updateOnHoldStatusForCallOff } from '../../../../api/call-off'
import { CallOff, CallOffStatus, Task } from '../../../../models/models'
import Card from '../../../ui/card/Card'
import CardHeader from '../../../ui/card/CardHeader'
import CardFooter from '../../../ui/card/CardFooter'
import CardRow from '../../../ui/card/CardRow'
import Button from '@mui/material/Button'
import { styled } from '@mui/styles'
import styledEngine from '@mui/styled-engine'
import { Box } from '@mui/system'
import { Chip } from '@mui/material'
import moment from 'moment'
import { callOffBuiltItemsCompletedDetails } from '../../../../util/util'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import SingleProjectViewNoData from '../SingleProjectViewNoData'
import { ActiveModal, openModal } from '../../../../store/project-call-off-slice'
import { CallOffFilterBar } from './CallOffFilterBar'
import { useMemo } from 'react'

const createCallOffCardChip = (status: CallOffStatus | string): React.ReactElement => {
    let backgroundColor: string
    let borderColor: string
    let color: string
    let label: string
    switch (true) {
        case status === 'task':
            backgroundColor = '#e6fbff'
            color = '#01d7ff'
            borderColor = '#01d7ff'
            label = 'Task'
            break
        case status.valueOf() === CallOffStatus.Requested.valueOf():
            backgroundColor = '#FFF7E6'
            color = '#FA8C16'
            borderColor = '#FA8C16'
            label = 'Requested'
            break
        case status.valueOf() === CallOffStatus.Approved.valueOf():
            backgroundColor = '#DCF7E9'
            color = '#00898A'
            borderColor = '#00898A'
            label = 'Approved'
            break
        case status.valueOf() === CallOffStatus.InProduction.valueOf():
            backgroundColor = '#FFD591'
            color = '#FA8C16'
            borderColor = '#FA8C16'
            label = 'In production'
            break
        case status.valueOf() === CallOffStatus.Completed.valueOf():
            backgroundColor = '#FFD591'
            color = '#FA8C16'
            borderColor = '#FA8C16'
            label = 'completed'
            break
        case status.valueOf() === CallOffStatus.Dispatched.valueOf():
            backgroundColor = '#FFD591'
            color = '#FA8C16'
            borderColor = '#FA8C16'
            label = 'Dispatched'
            break
        default:
            backgroundColor = '#FFD591'
            color = '#FA8C16'
            borderColor = '#FA8C16'
            label = 'No status'
            break
    }

    return (
        <Chip
            sx={{ color, whiteSpace: 'normal', flexWrap: 'wrap', borderColor, backgroundColor }}
            label={label}
            variant="outlined"
        />
    )
}

const Container = styledEngine(Box)({
    display: 'flex',
    flexDirection: 'row',
    width: '95%',
    margin: '50px',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
})

const HeaderLeft = styled(Box)({
    width: '50%',
})

const HeaderRight = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
})

export default function ViewCallOffs() {
    const dispatch = useAppDispatch()
    const { callOffs, tasks, filters } = useAppSelector((state) => state.projectCallOffs)

    const isCallOffSplittable = (callOff: CallOff) => {
        const details = callOffBuiltItemsCompletedDetails(callOff)

        if (Object.keys(details).length === 0) {
            return false
        }

        const values = Object.values(details)
        for (let i = 0; i < values.length; i++) {
            if (values[i].assemblyAllocatedAmount < values[i].callOffAmount) {
                return true
            }
        }

        return false
    }

    const showCreateAssemblyButton = (callOff: CallOff) => {
        let builtItemsWithAssemblyEnabled = 0,
            builtItemsWithAssemblyDisabled = 0,
            totalBuiltItems = 0,
            assembliesAlreadyCreated = 0

        callOff.builtItemCallOffs.forEach((pbico) => {
            if (!pbico.sopBuiltItemOrderline.builtItem.group?.assemblyEnabled) {
                if (
                    !pbico.sopBuiltItemOrderline.builtItem.group?.assemblyTransferEnabled &&
                    !pbico.sopBuiltItemOrderline.builtItem.group?.createPalletEnabled
                ) {
                    builtItemsWithAssemblyDisabled += pbico.amount
                }
            } else {
                builtItemsWithAssemblyEnabled += pbico.amount
            }
            totalBuiltItems += pbico.amount
        })

        callOff.assemblies.forEach((asm) => {
            assembliesAlreadyCreated += asm.amount
        })

        // also check if there any assemblies aready created
        // assemblies will be only be created for builtitems which have assembly active in its bomgrop

        if (builtItemsWithAssemblyEnabled === totalBuiltItems) {
            return true
        }

        if (builtItemsWithAssemblyDisabled === totalBuiltItems) {
            return false
        }

        if (
            assembliesAlreadyCreated !== 0 &&
            builtItemsWithAssemblyEnabled !== assembliesAlreadyCreated
        ) {
            // we have some assemblies created and some are still to be created
            return true
        }

        if (
            builtItemsWithAssemblyDisabled + builtItemsWithAssemblyEnabled === totalBuiltItems &&
            assembliesAlreadyCreated !== builtItemsWithAssemblyEnabled
        ) {
            return true
        }

        return false
    }

    const getCallOffNumberOfUnits = (callOff: CallOff) => {
        let amount = 0
        callOff?.builtItemCallOffs?.forEach((pbico) => {
            amount += pbico.amount
        })
        callOff?.stockItemCallOffs?.forEach((psico) => {
            amount += psico.amount
        })
        return amount.toString()
    }

    const getCallOffTotalBuildTime = (callOff: CallOff) => {
        let buildTime = 0
        callOff.builtItemCallOffs?.forEach((builtItemCallOff) => {
            const assemblyMinutes =
                builtItemCallOff.sopBuiltItemOrderline?.builtItem?.assemblyMinutes ?? 0
            if (assemblyMinutes !== undefined) {
                buildTime += assemblyMinutes * builtItemCallOff.amount
            }
        })
        return `${buildTime.toString()} minutes`
    }

    const getCallOffBuiltProductsAmount = (callOff: CallOff) => {
        let builtItems = 0
        let totalBuiltItems = 0
        const details = callOffBuiltItemsCompletedDetails(callOff)

        if (details && Object.keys(details).length > 0) {
            const values = Object.values(details)

            for (let i = 0; i < values.length; i++) {
                if (values[i]) {
                    builtItems += values[i]?.completedAmount
                    totalBuiltItems += values[i]?.callOffAmount
                }
            }
        }

        return `${builtItems}/${totalBuiltItems}`
    }

    async function updateOnHoldState(callOff: CallOff) {
        await updateOnHoldStatusForCallOff(callOff.id, !callOff.isOnHold)
    }

    const filteredCallOffs = useMemo(() => {
        return (
            callOffs
                .filter((callOff) => {
                    // Return all call offs if no status given - All label
                    if (filters.callOffStatus === null) return true

                    // Return call offs by status
                    if (callOff.status === filters.callOffStatus) return true

                    return false
                })
                // filter based on call off id
                .filter((callOff) => {
                    if (callOff.id.toString().includes(filters.filterCallOffId)) return true
                    return false
                })
        )
    }, [filters, callOffs])

    const filteredTasks = useMemo(() => {
        return tasks.filter(() => {
            // Return all tasks if no status given - All label
            if (filters.callOffStatus === null && filters.filterCallOffId === '') return true

            // Return tasks by filter value
            if (filters.callOffStatus === 'task') return true

            return false
        })
    }, [filters, tasks])

    // Will center the no data message
    const centerContent = 'space-around'

    // Will render flex items from left to right
    const flexContent = 'flex-start'

    const containerStyle =
        !tasks.length && !filteredCallOffs.length
            ? centerContent
            : filters.callOffStatus === 'task' && tasks.length > 0
            ? flexContent
            : filteredCallOffs.length >= 4 || !filteredCallOffs.length
            ? centerContent
            : flexContent

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <CallOffFilterBar listView={true} />
            </div>
            <Container
                style={{
                    justifyContent: containerStyle,
                }}
            >
                {!filteredCallOffs.length && !filteredTasks.length && <SingleProjectViewNoData />}
                {[...filteredCallOffs]
                    .sort((a: CallOff, b: CallOff) => a.id - b.id)
                    .map((callOff) => {
                        return (
                            <Box
                                key={callOff.id}
                                sx={{
                                    marginBottom: '20px',
                                    marginRight: filteredCallOffs.length > 3 ? '0' : '20px',
                                }}
                            >
                                <Card>
                                    <CardHeader>
                                        <HeaderLeft>{callOff.id}</HeaderLeft>
                                        <HeaderRight>
                                            {createCallOffCardChip(callOff.status)}
                                            {callOff.isOnHold && (
                                                <Chip
                                                    sx={{
                                                        marginTop: '10px',
                                                        color: '#ff1a1a',
                                                        whiteSpace: 'normal',
                                                        flexWrap: 'wrap',
                                                        borderColor: '#ff1a1a',
                                                        backgroundColor: '#ffcccc',
                                                    }}
                                                    label="Is on hold"
                                                    variant="outlined"
                                                />
                                            )}
                                        </HeaderRight>
                                    </CardHeader>
                                    <CardRow
                                        descriptor="No. of Units"
                                        value={getCallOffNumberOfUnits(callOff)}
                                    />
                                    <CardRow
                                        descriptor="Est. Time taken"
                                        value={getCallOffTotalBuildTime(callOff)}
                                    />
                                    <CardRow
                                        descriptor="Built Items Completed"
                                        value={getCallOffBuiltProductsAmount(callOff)}
                                    />
                                    <CardRow
                                        descriptor="Date to be completed by"
                                        value={
                                            moment(callOff?.dateToBeCompletedBy).format(
                                                'DD-MM-YYYY'
                                            ) || 'Not specified'
                                        }
                                    />
                                    <CardRow
                                        descriptor="Amount of different products"
                                        value={((): string => {
                                            let counter = 0
                                            if (callOff?.builtItemCallOffs) {
                                                counter += callOff?.builtItemCallOffs.length
                                            }
                                            if (callOff?.stockItemCallOffs) {
                                                counter += callOff?.stockItemCallOffs.length
                                            }
                                            return String(counter)
                                        })()}
                                    />
                                    <CardFooter>
                                        {[
                                            CallOffStatus.InProduction,
                                            CallOffStatus.Approved,
                                        ].includes(callOff.status) &&
                                            !callOff.isOnHold &&
                                            isCallOffSplittable(callOff) &&
                                            showCreateAssemblyButton(callOff) && (
                                                <Button
                                                    style={{
                                                        marginRight: '10px',
                                                        marginBottom: '10px',
                                                    }}
                                                    variant="outlined"
                                                    onClick={() => {
                                                        dispatch(
                                                            openModal({
                                                                modal: ActiveModal.CreateAssembly,
                                                                entityId: callOff.id,
                                                            })
                                                        )
                                                    }}
                                                >
                                                    Create assembly
                                                </Button>
                                            )}
                                        {[
                                            CallOffStatus.Requested,
                                            CallOffStatus.Approved,
                                            CallOffStatus.InProduction,
                                            CallOffStatus.Completed,
                                            CallOffStatus.Dispatched,
                                        ].includes(callOff.status) && (
                                            <Button
                                                style={{
                                                    marginRight: '10px',
                                                    marginBottom: '10px',
                                                }}
                                                variant="outlined"
                                                onClick={() => {
                                                    dispatch(
                                                        openModal({
                                                            modal: ActiveModal.Inspect,
                                                            entityId: callOff.id,
                                                        })
                                                    )
                                                }}
                                            >
                                                Inspect
                                            </Button>
                                        )}
                                        {[
                                            CallOffStatus.InProduction,
                                            CallOffStatus.Approved,
                                        ].includes(callOff.status) &&
                                            !callOff.isOnHold && (
                                                <Button
                                                    style={{
                                                        marginRight: '10px',
                                                        marginBottom: '10px',
                                                    }}
                                                    variant="outlined"
                                                    onClick={() => {
                                                        dispatch(
                                                            openModal({
                                                                modal: ActiveModal.Update,
                                                                entityId: callOff.id,
                                                            })
                                                        )
                                                    }}
                                                >
                                                    Update
                                                </Button>
                                            )}
                                        {[
                                            CallOffStatus.Approved,
                                            CallOffStatus.InProduction,
                                            CallOffStatus.Completed,
                                        ].includes(callOff.status) && (
                                            <Button
                                                style={{ marginRight: '10px' }}
                                                variant="outlined"
                                                onClick={() => updateOnHoldState(callOff)}
                                            >
                                                Set {!!callOff.isOnHold && ' not '} on hold
                                            </Button>
                                        )}
                                    </CardFooter>
                                </Card>
                            </Box>
                        )
                    })}

                {[...filteredTasks]
                    .sort((a: Task, b: Task) => a.id - b.id)
                    .map((task) => {
                        return (
                            <Box key={task.id} sx={{ marginBottom: '20px' }}>
                                <Card>
                                    <CardHeader>
                                        <HeaderLeft>{task.id}</HeaderLeft>
                                        <HeaderRight>{createCallOffCardChip('task')}</HeaderRight>
                                    </CardHeader>
                                    <CardRow descriptor="Type" value={task.type?.title || ''} />
                                    <CardRow descriptor="Description" value={task.description} />
                                    <CardRow descriptor="Link" value={task.link || ''} />
                                    <CardRow
                                        descriptor="Assigned to"
                                        value={`${task.assignedUser?.firstName} ${task.assignedUser?.lastName}`}
                                    />
                                    <CardRow
                                        descriptor="Date to be completed by"
                                        value={
                                            moment(task?.dateToBeCompletedBy).format(
                                                'DD-MM-YYYY'
                                            ) || 'Not specified'
                                        }
                                    />
                                    <CardRow
                                        descriptor="Completed at"
                                        value={
                                            task.completedAt
                                                ? moment(task?.completedAt).format('DD-MM-YYYY')
                                                : ''
                                        }
                                    />
                                </Card>
                            </Box>
                        )
                    })}
            </Container>
        </div>
    )
}

import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit'
import {
    CallOff,
    CallOffStatus,
    Project,
    ProjectBuiltItem,
    ProjectStockItem,
    SalesOrder,
    Task,
} from '../models/models'
import { toastFailure } from '../util/toast'
import { getProjectCallOffsRequest } from '../api/call-off'
import { getProjectBuiltItems } from '../api/projects/built-items'
import { getProjectStockItems } from '../api/projects/stock-items'
import { GetManySalesOrderRelations, getSalesOrdersRequest } from '../api/sales-orders'
import { getTasksRequest, TaskRelation } from '../api/tasks'

export enum ActiveModal {
    None = 'None',
    Inspect = 'Inspect',
    Update = 'Update',
    CreateAssembly = 'CreateAssembly',
}

export enum DispatchedStatus {
    All = 'All',
    Dispatched = 'Dispatched',
    NotDispatched = 'NotDispatched',
}

export enum ActiveView {
    DeliverySchedule = 'DeliverySchedule',
    List = 'List',
}

export enum PriceCalculation {
    Total = 'Total',
    Each = 'Each',
}

export interface CallOffPageFilters {
    excludedBomCodes: string[]
    excludedSalesOrders: string[]
    dispatchedStatus: DispatchedStatus
    priceCalculation: PriceCalculation
    filterCallOffId: string
    callOffStatus: CallOffStatus | string | null
}

export interface State {
    filters: CallOffPageFilters
    activeModal: ActiveModal
    callOffs: CallOff[]
    projectStockItems: ProjectStockItem[]
    projectBuiltItems: ProjectBuiltItem[]
    salesOrders: SalesOrder[]
    activeView: ActiveView
    modalEntityId: number | null
    tasks: Task[]
}

const initialState: State = {
    filters: {
        excludedSalesOrders: [],
        excludedBomCodes: [],
        dispatchedStatus: DispatchedStatus.All,
        priceCalculation: PriceCalculation.Total,
        filterCallOffId: '',
        callOffStatus: null,
    },
    activeModal: ActiveModal.None,
    callOffs: [],
    projectStockItems: [],
    salesOrders: [],
    projectBuiltItems: [],
    activeView: ActiveView.DeliverySchedule,
    modalEntityId: null,
    tasks: [],
}

export const projectHistorySlice = createSlice({
    name: 'projectCallOffs',
    initialState,
    reducers: {
        resetState(state) {
            state.filters = initialState.filters
            state.activeModal = initialState.activeModal
            state.callOffs = initialState.callOffs
            state.projectStockItems = initialState.projectStockItems
            state.projectBuiltItems = initialState.projectBuiltItems
            state.salesOrders = initialState.salesOrders
            state.activeView = initialState.activeView
            state.modalEntityId = initialState.modalEntityId
            state.tasks = initialState.tasks
        },
        setCallOffs(state, action: PayloadAction<CallOff[]>) {
            state.callOffs = action.payload
        },
        setProjectStockItems(state, action: PayloadAction<ProjectStockItem[]>) {
            state.projectStockItems = action.payload
        },
        setProjectBuiltItems(state, action: PayloadAction<ProjectBuiltItem[]>) {
            state.projectBuiltItems = action.payload
        },
        setSalesOrders(state, action: PayloadAction<SalesOrder[]>) {
            state.salesOrders = action.payload
        },
        setFilters(state, action: PayloadAction<CallOffPageFilters>) {
            state.filters = action.payload
        },
        openModal(state, action: PayloadAction<{ modal: ActiveModal; entityId: number | null }>) {
            state.activeModal = action.payload.modal
            state.modalEntityId = action.payload.entityId
        },
        setActiveView(state, action: PayloadAction<ActiveView>) {
            state.activeView = action.payload
        },
        setTasks(state, action: PayloadAction<Task[]>) {
            state.tasks = action.payload
        },
        closeModal(state) {
            state.activeModal = ActiveModal.None
            state.modalEntityId = null
        },
    },
})

export const {
    resetState,
    setCallOffs,
    setSalesOrders,
    setProjectBuiltItems,
    setProjectStockItems,
    closeModal,
    openModal,
    setActiveView,
    setFilters,
    setTasks,
} = projectHistorySlice.actions

export const fetchEntitites = (project: Project) => async (dispatch: Dispatch) => {
    const [callOffs, projectBuiltItems, projectStockItems, salesOrders, tasks] = await Promise.all([
        getProjectCallOffsRequest(project!.id).then((response) => {
            if (!response.successful) {
                toastFailure('An error occurred while fetching call offs.')
                throw Error('An error occurred while fetching call offs.')
            }
            return response.data
        }),

        getProjectBuiltItems(project!.id, {
            builtItem: true,
            used: true,
        }).then((response) => {
            if (!response.successful) {
                toastFailure('An error occurred while fetching project built items.')
                throw Error('An error occurred while fetching project built items.')
            }
            return response.data.projectBuiltItems
        }),

        getProjectStockItems(project!.id, {
            stockItem: true,
            used: true,
        }).then((response) => {
            if (!response.successful) {
                toastFailure('An error occurred while fetching project stock items.')
                throw Error('An error occurred while fetching project stock items.')
            }
            return response.data.projectStockItems
        }),

        getSalesOrdersRequest({
            relations: [
                GetManySalesOrderRelations.Projects,
                GetManySalesOrderRelations.Orderlines,
                GetManySalesOrderRelations.CallOffs,
            ],
            withReservedOrderlineAmounts: true,
            projectId: project.id,
            withCount: false,
        }).then((response) => {
            if (!response.successful) {
                toastFailure('An error occurred while fetching sales orders.')
                throw Error('An error occurred while fetching sales orders.')
            }
            return response.data.entities
        }),
        getTasksRequest({
            projectId: project.id,
            relations: [TaskRelation.AssignedTo, TaskRelation.Type],
        }).then((response) => {
            if (!response.successful) {
                toastFailure('An error occurred while fetching sales orders.')
                throw Error('An error occurred while fetching sales orders.')
            }
            return response.data.entities
        }),
    ])

    dispatch(setSalesOrders(salesOrders))
    dispatch(setCallOffs(callOffs))
    dispatch(setProjectBuiltItems(projectBuiltItems))
    dispatch(setProjectStockItems(projectStockItems))
    dispatch(setTasks(tasks))
}

export default projectHistorySlice.reducer
